<template>
  <div ref="rootEl" class="keyfacts-shared p-xxxl" :class="`bg-${color}`">
    <h2
      v-if="content.title"
      ref="titleEl"
      class="keyfacts-shared__title wrapper"
      :class="`title-${titleColor}`"
      v-html="content.title"
    ></h2>
    <p
      v-if="content.text"
      ref="textEl"
      class="keyfacts-shared__text wrapper"
      v-html="content.text"
    ></p>

    <ul class="keyfacts-shared__items wrapper mt-xxl">
      <li
        v-for="item of content.items"
        ref="itemsEl"
        :key="item.title"
        class="keyfacts-shared__item"
      >
        <figure
          v-if="item.picture"
          class="keyfacts-shared__item__picture-outer"
        >
          <img
            v-src="item.picture"
            class="keyfacts-shared__item__picture"
            :sets="['160']"
            :alt="item.picture.alt"
          />
        </figure>

        <SvgSprite
          v-else-if="item.icon"
          class="keyfacts-shared__item__icon icon"
          :symbol="item.icon.slug"
          :size="item.icon.viewbox"
        />

        <p
          v-if="item.title"
          class="keyfacts-shared__item__title h4"
          v-html="item.title"
        ></p>
        <p
          v-if="item.text"
          class="keyfacts-shared__item__text list"
          v-html="item.text"
        ></p>
      </li>
    </ul>

    <div
      v-if="content.ctas?.length"
      class="keyfacts-shared__ctas wrapper mt-xxl"
    >
      <Action
        v-for="(cta, index) in content.ctas.filter(cta => cta !== undefined)"
        ref="ctasEl"
        :key="cta?.url || index"
        :content="{
          ...cta,
          modifiers:
            index === 0 ? ['btn', 'cta'] : ['btn', 'bordered', 'black'],
        }"
        class="keyfacts-shared__cta"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { Icon, Link, Picture } from '@/types'

export interface KeyfactsSharedItem {
  picture?: Picture
  title: string
  text?: string
  icon?: Icon
}

export interface KeyfactsShared {
  title?: string
  text?: string
  items: KeyfactsSharedItem[]
  ctas?: Array<Link | undefined>
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import GAction from '@/components/global/Action.vue'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<KeyfactsShared>,
    required: true,
  },
  color: {
    type: String,
    required: false,
    default: 'violet',
  },
  titleColor: {
    type: String,
    required: false,
    default: '',
  },
})
// Refs
const rootEl = ref<HTMLElement | null>(null)
const titleEl = ref<HTMLElement | null>(null)
const textEl = ref<HTMLElement | null>(null)
const itemsEl = ref<HTMLElement[]>([])
const ctasEl = ref<InstanceType<typeof GAction>[]>([])

// Create appear animation
const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value, ...itemsEl.value]
  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
    },
  })
  const duration = 0.3
  const ease = '8020'

  tl.set(rootEl.value, { clearProps: 'opacity' })

  if (titleEl.value) {
    animatedEls.push(titleEl.value)

    tl.from(titleEl.value, { opacity: 0, y: 20, duration, ease })
  }

  if (textEl.value) {
    animatedEls.push(textEl.value)

    tl.from(textEl.value, { opacity: 0, y: 20, duration, ease }, '<+=0.1')
  }

  tl.fromTo(
    itemsEl.value,
    {
      opacity: 0,
      x: -30,
    },
    {
      opacity: 1,
      x: 0,
      ease,
      duration,
      stagger: 0.05,
    },
    '<+=0.1'
  )

  if (ctasEl.value?.length) {
    const els = ctasEl.value.map(cta => cta.$el)

    animatedEls.push(...els)
    tl.fromTo(
      els,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        ease,
        duration,
        stagger: 0.15,
      },
      '-=0.2'
    )
  }
}

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(rootEl.value, () => {
    appear()
  })
})
</script>

<style lang="scss" scoped>
$bp: m;

.keyfacts-shared {
  position: relative;
  background: var(--c-accent-bg);

  @include mq($until: $bp) {
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
  }
}

.keyfacts-shared__title {
  @extend %text-center;
  @include section-title;

  margin-bottom: $spacing;
}

.keyfacts-shared__text {
  @extend %text-center;

  @include mq($bp) {
    --wrapper-padding: 4;
  }

  @include mq(xxl) {
    --wrapper-padding: 5;
  }
}

.keyfacts-shared__items {
  margin-bottom: 0;
  list-style-type: none;

  &:first-child {
    margin-top: 0;
  }

  @include mq($until: $bp) {
    margin-top: $spacing * 1.5;
  }

  @include mq($bp) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $spacing * 2 0;
  }
}

.keyfacts-shared__item {
  @extend %fw-normal;

  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 4rem 1fr;
  align-items: center;
  gap: 0 $spacing * 0.5;
  padding-bottom: $spacing * 0.5;
  border-bottom: 0.1rem solid var(--c-accent-border);

  & + & {
    margin-top: $spacing * 1.5;
  }

  @include mq($bp) {
    grid-template-columns: 6rem 1fr;
    gap: 0 $spacing * 1.5;
    width: col(8.5, var(--available-columns));
    padding-bottom: $spacing;

    & + & {
      margin-top: 0;
    }
  }

  @include mq(wrapper) {
    width: col(7, var(--available-columns));
  }
}

.keyfacts-shared__item__picture-outer,
.keyfacts-shared__item__icon {
  position: relative;
  grid-row: 1/3;
  grid-column: 1/2;
  align-self: center;
  width: 100%;
  aspect-ratio: 1;
}

.keyfacts-shared__item__picture {
  @include image-fit(contain);
}

.keyfacts-shared__item__icon {
  fill: var(--c-accent);

  .keyfacts-product & {
    fill: $c-main;
  }
}

.keyfacts-shared__item__title {
  @extend %fw-bold;
}

.keyfacts-shared__ctas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing * 0.5;
  align-items: flex-start;

  @include mq($until: $bp) {
    margin-top: $spacing * 2;
  }

  @include mq($bp) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $spacing;
  }
}
</style>
